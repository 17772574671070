import { useEffect, useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { Modal, Tab, Tabs } from 'react-bootstrap';
import { toast } from 'react-hot-toast';

// TABS
import ScheduleTab from "../../components/tabs/resident-details/schedule.tab";
import LedgerTab from "../../components/tabs/resident-details/ledger.tab";
import OnBoardingInfoTab from "../../components/tabs/resident-details/onboarding-info.tab";
import ReferAFriendTab from "../../components/tabs/resident-details/refer-a-friend.tab";
import ExitTab from "../../components/tabs/resident-details/exit.tab";

// Responsive Tab
import LedgerRespTab from "../../components/tabs/resident-details/ledger-responsive.tab";
import ExitRespTab from "../../components/tabs/resident-details/exit-responsive.tab";

import { fetchResidentDetails, fetchResidentOnboardingDetails } from "../../helpers/services/api.services";
import withDefaultDashBoardLayout from "../../components/layouts/withDefaultDashBoardLayout";
import { getFiscalYear } from "../../helpers/common/common.function";
import CollectionTab from "../../components/tabs/resident-details/collection-responsive.tab";
import PdcTab from "../../components/tabs/resident-details/pdc.tab";
import NewTicketModal from "../../components/customModals/NewTicketModal";
import styles from '../revenue/revenueManualPage/modals/UploadAdjustmentModal/index.module.scss'
import GlobalStateContext from "../../GlobalStateContext";
import { activateExpiredContracts } from "../../helpers/services/admin.services";

function ResidentDetails() {

    const {roles} = useContext(GlobalStateContext);
    const [residentInfo, setResidentInfo] = useState({});
    const [residentOnboardingInfo, setResidentOnboardingInfo] = useState(null);
    const [residentBasicInfo, setResidentBasicInfo] = useState(null);
    const [isMobile, setIsMobile] = useState(false); // State to track if the device is mobile
    const { residentId } = useParams();
    const [activeTab, setActiveTab] = useState("schedule"); // Track the active tab
    const [scrollToCollection, setScrollToCollection] = useState(false); 
    const [showNewTicketModal, setShowNewTicketModal] = useState(false);
    const [showExtendContractModal, setShowExtendContractModal] = useState(false); // New state for the Extend Contract modal
    const [loading, setLoading] = useState(false);

    console.log("jfbksjfbvua",residentInfo)

    useEffect(() => {
        // Function to check if the device is mobile
        const checkIsMobile = () => {
            setIsMobile(window.matchMedia("(max-width: 768px)").matches);
        };

        // Initial check
        checkIsMobile();

        // Add event listener for screen resizing
        window.addEventListener("resize", checkIsMobile);

        return () => {
            // Clean up the event listener
            window.removeEventListener("resize", checkIsMobile);
        };
    }, []);

    useEffect(() => {
        if (residentId != undefined) {
            loadResidentsDetails();
            loadResidentOnboardingDetails();
        }       
    }, [residentId]);

    const loadResidentsDetails = async () => {
        let response = await fetchResidentDetails(residentId);

        if (response && response.status === "success") {
            setResidentInfo(response.data);
            setResidentBasicInfo(response?.data?.basic_details);
        }
    }

    const loadResidentOnboardingDetails = async () => {
        let response = await fetchResidentOnboardingDetails(residentId);

        if (response && response.status === "success") {
            setResidentOnboardingInfo(response.data);
        }
    }

    const handleActivateExpiredContracts = async () => {
        setLoading(true)
        try{
            let response = await activateExpiredContracts(residentId)

            if(response && response?.status === "success"){
                toast.success(response?.data[0]?.message || "Contract Activated successfully")
            }
            else if(response && response?.status === "failure"){
                toast.error(response?.error)
            }
            else{
                toast.error("Error while activating contract")
            }
        } catch(error){
            toast.error("Error while activating contract")
        }  finally{
            setLoading(false)
            setShowExtendContractModal(false)
        }
    }

    const handleGoToCollection = () => {
        setActiveTab("schedule"); 
        setScrollToCollection(true);
    };

    const handleOpenModal = () => {
        setShowExtendContractModal(true);
    }

    const handleCloseModal = () => {
        setShowExtendContractModal(false);
    }

    useEffect(() => {
        if (activeTab === "schedule" && scrollToCollection) {
            setTimeout(() => {
                const element = document.getElementById("collectionSchedule");
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
                setScrollToCollection(false);
            }, 100);
        }
    }, [activeTab, scrollToCollection]);

    return (
        <>
            {/* Web Content */}
            <div className="main-content-web">
                <div className="rdp-right-side-header">
                    <div className="d-flex justify-content-between align-items-center pb-2">
                        <div className="d-flex align-items-center">
                            <Link to={'/residents'}>
                                <img src="/images/Back.svg" alt="Back" />
                            </Link>
                            <div className="tit_point mx-3">{getFiscalYear()}</div>
                            <h2 className="m-0">{residentOnboardingInfo?.resident_name}</h2>
                        </div>
                        <div className="ms-auto d-flex align-items-center gap-12 mt-2">
                            {residentOnboardingInfo && residentInfo?.contract_details?.status === "expired" && (roles?.super_admin || roles?.tech_admin) ?
                                <button style={{
                                    backgroundColor: "#fff4ed",
                                    color: "#ff5700",
                                    border: "1px solid #ff5700",
                                    borderRadius: "100px",
                                    fontWeight: 800,
                                    fontSize: "13px",
                                    padding: "14px 16px",
                                    }} 
                                    onClick={handleOpenModal}>
                                    ACTIVATE CONTRACT
                                </button>
                            : null
                        }
                        <Modal
                            show={showExtendContractModal}
                            onHide={handleCloseModal}
                            backdrop="static"
                            dialogClassName={styles['dialog-container']}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id={styles['#modal-title']}>Contract Activation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className={styles['body-content']}>
                                    <div>
                                        <p className={"mb-0 " + styles['label-title']}>Are you sure you want activate the contract?</p>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className={'btn ' + styles['btn-cancel']} onClick={handleCloseModal}>No</button>
                                <button className={'btn ' + styles['btn-submit']} onClick={handleActivateExpiredContracts} disabled={loading}>
                                    {loading ? "Processing..." : "Yes"}
                                </button>
                            </Modal.Footer>
                        </Modal>
                        {residentOnboardingInfo && 
                            <div>
                            <button
                    className="basic-button"
                    onClick={() => setShowNewTicketModal(!showNewTicketModal)}
                  >
                    <img src="/images/plus-circle.svg" className="pr-2" alt="" />
                    New Ticket
                  </button>
                            </div>
                        
                        }
                        </div>
                    </div>
                    <div className="breadcum">
                        <ul>
                            <li><Link to={'/'}>Tenants</Link> <span>/</span></li>
                            <li><Link to={'/residents'}>Residents</Link> <span>/</span></li>
                            <li>{residentOnboardingInfo?.ys_id + ' - ' + residentOnboardingInfo?.resident_name}</li>
                        </ul>
                    </div>
                </div>

                {/* Details Tab View */}
                <div className="main_tab">
                    <Tabs
                        activeKey={activeTab}
                        id="resident-details-view"
                        className="mb-4"
                        onSelect={(k) => setActiveTab(k)}
                    >
                        <Tab eventKey="schedule" title="Schedule">
                            <ScheduleTab loadResidentsDetails={loadResidentOnboardingDetails} residentInfo={residentInfo} residentOnboardingInfo={residentOnboardingInfo} />
                        </Tab>
                        <Tab eventKey="ledger" title="Ledger">
                            <LedgerTab 
                                residentId={residentId}
                            />
                        </Tab>
                        <Tab eventKey="onboarding-info" title="Onboarding-info">
                            <OnBoardingInfoTab residentInfo={residentInfo} residentOnboardingInfo={residentOnboardingInfo}/>
                        </Tab>
                        <Tab eventKey="pdc" title="PDC">
                            <PdcTab resident_id={residentId} residentInfo={residentInfo} onboarding_info={residentOnboardingInfo}  />
                        </Tab>
                        <Tab eventKey="refer-a-friend" title="Refer a friend">
                            <ReferAFriendTab residentOnboardingInfo={residentOnboardingInfo} />
                        </Tab>
                        <Tab eventKey="exit" title="Exit">
                            <ExitTab
                                residentId={residentId}
                                residentInfo={residentInfo}
                                residentOnboardingInfo={residentOnboardingInfo}
                                loadResidentsDetails={() => loadResidentsDetails()}
                                loadResidentOnboardingDetails={() => loadResidentOnboardingDetails()}
                                handleGoToCollection={handleGoToCollection}
                            />
                        </Tab>
                    </Tabs>
                </div>
            </div>

            {/* Mobile Content - Only render when the device is mobile */}
            {isMobile && (
                <div className="main-content-mobile">
                    <div className="mob_details_header table_main_tit d-flex justify-content-between align-items-center pb-20">
                        <div>
                            <h2><Link to={'/residents'}><img src="/images/white_Back.svg" className="pr-2" alt="" /></Link>{residentOnboardingInfo?.resident_name ?? "--"}</h2>
                        </div>
                        <div className="details_right_top_part">{residentOnboardingInfo?.ys_id + ' - ' + residentOnboardingInfo?.resident_name}</div>
                    </div>

                    {/* Details Tab View */}
                    <div className="mob_details_middle bg_white">
                        <div className="main_tab">
                            <Tabs
                                defaultActiveKey="collection"
                                id="resident-details-view"
                                className="mb-4"
                            >
                                <Tab eventKey="collection" title="Collection">
                                    <CollectionTab
                                        residentId={residentId}
                                    />
                                </Tab>
                                <Tab eventKey="ledger" title="Ledger">
                                    <LedgerRespTab
                                        residentId={residentId}
                                    />
                                </Tab>
                                <Tab eventKey="exit" title="Exit">
                                    <ExitRespTab
                                        residentId={residentId}
                                        residentInfo={residentInfo}
                                        residentOnboardingInfo={residentOnboardingInfo}
                                        loadResidentsDetails={() => loadResidentsDetails()}
                                        loadResidentOnboardingDetails={() => loadResidentOnboardingDetails()}
                                    />
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            )}
                  {showNewTicketModal && (
        <NewTicketModal
          setShowModal={setShowNewTicketModal}
          showModal={showNewTicketModal}
          residentData={residentInfo}
        />
        )}
        </>
    );
}

export default withDefaultDashBoardLayout(ResidentDetails);